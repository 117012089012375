var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.header,"hide-default-footer":"","search":_vm.search,"items":_vm.list,"item-key":"id","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('PageHeader',{attrs:{"header":_vm.pageHeader},scopedSlots:_vm._u([{key:"mainButton",fn:function(){return [_c('CompanyInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['EMP', 'CR']),expression:"['EMP', 'CR']",modifiers:{"permission":true}}],attrs:{"productList":_vm.productList,"list":_vm.list,"loadData":_vm.loadData,"entity":{}}}),_c('v-btn',{staticClass:"ml-5 primary",on:{"click":_vm.loadOmie}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]},proxy:true}])}),_c('v-text-field',{staticClass:"padding-search mt-5 ml-2 mr-2",attrs:{"label":_vm.$t('filter'),"hint":_vm.$t('searchItem'),"outlined":"","color":"primary","append-icon":"mdi-magnify","clearable":""},on:{"input":_vm.loadData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.active === true ? '#4CAF50' : '#F90000'}},[_c('span',[_vm._v(" "+_vm._s(item.active === true ? _vm.$t("active") : _vm.$t("inactive")))])])]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCNPJ(item.cnpj))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"icons"},[_c('CompanyInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['EMP', 'UP']),expression:"['EMP', 'UP']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"productList":_vm.productList,"entity":item,"companyProductPlan":item.product,"loadData":_vm.loadData,"list":_vm.list}}),_c('CompanyInput',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['EMP', 'UP']),expression:"['EMP', 'UP']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"productList":_vm.productList,"entity":item,"companyProductPlan":item.product,"loadData":_vm.loadData,"list":_vm.list,"isView":true}}),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PPE', 'VI']),expression:"['PPE', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
              name: 'CompanyProductList',
              query: { companyId: item.id, item: item },
            }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('title.CompanyProductList')}},[_vm._v(" mdi-briefcase ")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['PRL', 'VI']),expression:"['PRL', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
              name: 'ProfileList',
              query: { companyId: item.id, item: item },
            }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('title.profile')}},[_vm._v(" mdi-folder-account-outline ")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['INT', 'VI']),expression:"['INT', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
              name: 'IntegrationList',
              query: { companyId: item.id, item: item },
            }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('integration')}},[_vm._v(" mdi-lan-pending ")])],1),_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['USU', 'VI']),expression:"['USU', 'VI']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"to":{
              name: 'UserList',
              query: { companyId: item.id, item: item },
            }}},[_c('v-icon',{attrs:{"dense":"","title":_vm.$t('title.user')}},[_vm._v(" mdi-account ")])],1),_c('DeleteCascateCompanyProductPlan',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['EMP', 'DE']),expression:"['EMP', 'DE']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"entityDelete":item}}),_c('ConfirmDelete',{directives:[{name:"has-permission",rawName:"v-has-permission.permission",value:(['EMP', 'DE']),expression:"['EMP', 'DE']",modifiers:{"permission":true}}],staticClass:"icon-distance",attrs:{"loadData":_vm.loadData,"path":_vm.path,"entity":item,"list":_vm.list}})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("noData"))+" ")]},proxy:true}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v(" "+_vm._s(_vm.$t("itemsPerPage"))+" ")]),_c('v-select',{staticClass:"font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",attrs:{"hide-details":"","type":"number","dense":"","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","items":_vm.itemsPerPageList,"value":_vm.itemsPerPage,"item-text":"value","item-value":"value","return-object":false,"rules":[_vm.$rules.fieldsRequired]},on:{"input":_vm.loadData},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.pageCount,"total-visible":10,"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#d3075e","circle":""},on:{"input":_vm.loadData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }