

































































































































































































































import { Component } from "vue-property-decorator";
import { ICompany } from "../../types/Company/ICompany";
import { TProduct } from "../../types/Company/TProduct";
import { TCompanyProductPlan } from "../../types/Company/TCompanyProductPlan";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import ProductConfig from "./ProductConfig.vue";
import DeleteCompanyProductPlan from "../../components/commonComponents/DeleteCompanyProductPlan.vue";
import DirectAuthentication from "../../components/commonComponents/DirectAuthentication.vue";

import {
  BaseBreadcrumb,
  Status,
  ConfirmDelete,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import LockUnlock from "../../components/commonComponents/LockUnlock.vue";
import CompanyProductInput from "./CompanyProductInput.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { itemsPerPage } from "../../types/itemsPerPage";
import { Breadcrumb } from "../../types/Breadcrumb";
import { HeaderItem } from "../../types/HeaderItem";
import moment from "moment";
import { EventBus } from "./../../services/eventBus";

@Component({
  components: {
    List,
    DeleteCompanyProductPlan,
    BaseBreadcrumb,
    Status,
    CompanyProductInput,
    ProductConfig,
    LockUnlock,
    ConfirmDelete,
    DirectAuthentication,
  },
})
export default class CompanyProductList extends Request {
  companyId = this.$route.query.companyId;
  entity: ICompany = {};
  title:string = `${translate("companyProduct")}`;
  dialog:boolean = false;
  reason: string = "";
  companyProductConfig: TCompanyProductPlan[] = [];
  observation: string = "";
  productList: TProduct[] = [];
  resultVersion: string = "";
  search: string = "";
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 0;
  itemsPerPage: number = 10;
  version: string = "--";

  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      to: "/company/",
      disabled: false,
    },
    {
      text: `${translate("companyProduct")}`,
      disabled: true,
    },
  ];
  customColumns = [
    {
      slotName: "createdAt",
      itemValue: "createdAt",
    },
    {
      slotName: "companyProductPlan.active",
      itemValue: "companyProductPlan.active",
    },
    {
      slotName: "companyProductPlan.isBlocked",
      itemValue: "companyProductPlan.isBlocked",
    },
  ];
  header: HeaderItem[] = [
    { text: `${translate("product")}`, align: "start", value: "name" },
    {
      text: `${translate("version")}`,
      align: "center",
      value: "version",
    },
    {
      text: `${translate("plan")}`,
      align: "center",
      value: "plan.name",
    },
    {
      text: `${translate("active")}`,
      align: "center",
      value: "companyProductPlan.active",
    },
    {
      text: `${translate("isBlocked")}`,
      align: "end",
      value: "companyProductPlan.isBlocked",
    },
    {
      text: `${translate("creatAt")}`,
      align: "center",
      value: "createdAt",
    },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-briefcase",
    titlePage: `${translate("companyProduct")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  newList: any = [];
  path: string = "";
  list: ICompany[] = [];

  created() {
    EventBus.$on("getAddInfo", () => {
      this.$message?.errorMessage(`${translate("error.domainNotFound")}`);
    });
  }
  destroyed() {
    EventBus.$off("getAddInfo");
  }
  async mounted(): Promise<any> {
    if (this.$route.query.item) {
      const item = this.$route.query.item as ICompany;
      this.entity = item;
    }

    const result = await this.get(this.paths.productPlanListPath);
    this.productList = result;

    await this.loadData();
  }
  async openDialog(companyProduct) {
    const result = await this.get(
      this.paths.companyProductList + this.companyId
    );

    this.newList = result.find((item) => item.id === companyProduct.id);

    this.dialog = true;

    if (this.newList.companyProductPlan.reason === "BF") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "financialBlockage"
      )}`);
    }
    if (this.newList.companyProductPlan.reason === "BA") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "administrativeBlocking"
      )}`);
    }
    if (this.newList.companyProductPlan.reason === "SN") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "notActivated"
      )}`);
    }
    if (this.newList.companyProductPlan.reason === "PR") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "PaymentMade"
      )}`);
    }
    if (this.newList.companyProductPlan.reason === "PA") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "scheduledPayment"
      )}`);
    }
    if (this.newList.companyProductPlan.reason === "SA") {
      return (this.newList.companyProductPlan.reason = `${translate(
        "activeService"
      )}`);
    }
  }
  async loadData() {
    this.path = this.paths.companyProductPlanPath;
    const result = await this.get(
      this.paths.companyProductPageList +
        this.companyId +
        `?&take=${this.itemsPerPage}&skip=${1}`
    );

    this.list = result.result.map((item) => {
      item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
      if (item.scope === "OMI") {
        item.version = "S/N";
      }
      return item;
    });

    const omiItem = result.result.find((item) => item.scope === "OMI");

    let version = "";

    if (omiItem && omiItem.companyProductConfig) {
      version = await this.getVersionForOMI(omiItem.companyProductConfig.id);
    }

    this.list = result.result.map((item) => {
      if (item.scope === "OMI") {
        item.version = version;
      }

      return item;
    });

    this.pageCount = Math.ceil(result.count / this.itemsPerPage);
  }
  async getVersionForOMI(configId) {
    const resultVersion = await this.get(
      this.paths.versionPath + `${configId}`
    );
    return resultVersion.version;
  }
  async loadPagination() {
    const resultPage = await this.get(
      this.paths.companyProductPageList +
        this.companyId +
        `?take=${this.itemsPerPage}&skip=${this.page}`
    );
    this.list = resultPage.result.map((item) => {
      item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
      if (item.scope === "OMI") {
        item.version = "S/N";
      }
      return item;
    });
    const omiItem = resultPage.result.find((item) => item.scope === "OMI");

    let version = "";

    if (omiItem && omiItem.companyProductConfig) {
      version = await this.getVersionForOMI(omiItem.companyProductConfig.id);
    }

    this.list = resultPage.result.map((item) => {
      if (item.scope === "OMI") {
        item.version = version;
      }

      return item;
    });

    this.list = resultPage.result;
    this.pageCount = Math.ceil(resultPage.count / this.itemsPerPage);
  }
  resolveVersion(version) {
    const result = version ? version : "S/N";
    return result;
  }
  updateOptions() {
    this.loadData();
  }
}
